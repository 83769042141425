import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref,cb) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
   
        if(event.target.tagName !== "HTML" && event.target.tagName !== "NAV") {
            if (ref.current && !ref.current.contains(event.target)) {
                cb();
            }
        }
    }

    
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
      
    }
  }, [ref])
}


/**
 * Component that alerts if you click outside of it
 */
function OutsideAlerter(props) {
  useEffect(()=>{
    return () => {
    // console.log("unmount");

    }
},[])
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef,props.handleSubRoutine)

  return <div ref={wrapperRef}>{props.children}</div>
}

OutsideAlerter.propTypes = {
  children: PropTypes.element.isRequired,
  handleSubRoutine: PropTypes.func,
}
OutsideAlerter.defaultProps = {
  children: <div> </div>,
  handleSubRoutine: () => {},
}
export default OutsideAlerter
