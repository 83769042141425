import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Highlight, connectAutoComplete, connectHighlight } from 'react-instantsearch-dom';
import AutoSuggest from 'react-autosuggest';

// Capitalize
const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
// Capitalize

// Custom highlight
const HighlightNew = ({ highlight, attribute, hit }) => {
  const parsedHit = highlight({
    highlightProperty: '_highlightResult',
    attribute,
    hit,
  });

  return (
    <span className="text-capital-loc">
      {parsedHit.map(
        (part, index) =>
          part.isHighlighted ? (
            <mark key={index}>{part.value.replace(/-/g, " ")}</mark>
          ) : (
            <span key={index}>{part.value.replace(/-/g, " ")}</span>
          )
      )}
    </span>
  );
};

const CustomHighlight = connectHighlight(HighlightNew);
// Custom highlight

class AutoCompleteLocation extends Component {
    static propTypes = {
      hits: PropTypes.arrayOf(PropTypes.object).isRequired,
      currentRefinement: PropTypes.string.isRequired,
      refine: PropTypes.func.isRequired,
      onSuggestionSelected: PropTypes.func.isRequired,
      onSuggestionCleared: PropTypes.func.isRequired,
    };
  
    state = {
      value: this.props.currentRefinement,
    };
  
    onChange = (_, { newValue }) => {
      if (!newValue) {
        this.props.onSuggestionCleared();
      }
      if(this.props.handleChange) {
      this.props.handleChange(newValue);
      }

      this.setState({
        value: newValue?.replace(/-/g, ' '),
      });
    };
  
    onSuggestionsFetchRequested = ({ value }) => {
      this.props.refine(value);
    };
  
    onSuggestionsClearRequested = () => {
      this.props.refine();
    };
  
    getSuggestionValue(hit) {
      return hit.city_search;
    }
  
    renderSuggestion(hit) {
      return <CustomHighlight attribute="city_search" hit={hit} />;
    }
  
    render() {
      const { hits, onSuggestionSelected } = this.props;
      const { value } = this.state;
  
      const inputProps = {
        placeholder: 'Enter Your Location',
        onChange: this.onChange,
        value,      
      };

      // Remove duplicates
      function getUniqueListBy(hits, key) {
        return [...new Map(hits.map(item => [item[key], item])).values()]
      }
      const hitsNew = getUniqueListBy(hits, 'city_search')
      // Remove duplicates
  
      return (
        <AutoSuggest
          suggestions={hitsNew}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
        />
      );
    }
  }
  
  export default connectAutoComplete(AutoCompleteLocation);