export const URLS = {
    BLOGS: {
        name: 'Blog',
        alias: '/advice/blogs'
      },
      ADVICE: {
        name: 'Advice',
        alias: '/advice'
      },
      CANDIDATE: {
        name: 'Candidate',
        alias: '/for-candidates'
      },
      REVIEWS: {
        name: 'Reviews',
        alias: '/about/reviews'
      },
      EMPLOYERS: {
        name: 'EMPLOYERS',
        alias: '/for-employers'
      },
      JOBS: {
        name: 'Jobs',
        alias: '/for-candidates/job-search'
      },
      JOBSA: {
        name: 'Jobs',
        alias: '/for-candidates/job-apply'
      },
      JOBSD: {
        name: 'Jobs',
        alias: '/for-candidates/job-details'
      },



}



export const BLOGS_PAGE_URL = URLS.BLOGS;
export const ADVICE_PAGE_URL = URLS.ADVICE;
export const CANDIDATE_PAGE_URL = URLS.CANDIDATE
export const EMPLOYERS_PAGE_URL = URLS.EMPLOYERS
export const JOBS_PAGE_URL = URLS.JOBS
export const JOBSA_PAGE_URL = URLS.JOBSA
export const JOBSD_PAGE_URL = URLS.JOBSD
export const REVIEWS_PAGE_URL = URLS.REVIEWS