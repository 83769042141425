import React, { Component } from 'react';
import PropTypes from 'prop-types';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  Configure,
  Hits,
  Highlight,
  connectSearchBox,
  RefinementList
} from 'react-instantsearch-dom';
import AutoCompleteLocation from './search-Autocomplete-location';
import './assets/styles/_index.scss';

const VirtalSearchBox = connectSearchBox(() => null);

const searchClient = algoliasearch(
	`${process.env.GATSBY_ALGOLIA_APP_ID}`,
	`${process.env.GATSBY_ALGOLIA_SEARCH_KEY}`
);

class SearchInputBoxLocation extends Component {
  state = {
    query: '',
  };

  onSuggestionSelected = (_, { suggestion }) => {
    this.setState({
      query: suggestion.city_search,
    });
  };

  onSuggestionCleared = () => {
    this.setState({
      query: '',
    });
  };

  render() {
    const { query } = this.state;
    const index_name = `${process.env.GATSBY_ALGOLIA_INDEX_NAME}`
    
    return (
      <div className="form-group autosuggest_container">
        <InstantSearch indexName={index_name} searchClient={searchClient}>
          <div className="d-none">
              <RefinementList
                  attribute="publish"
                  defaultRefinement={["true"]}
              />
          </div>
          <Configure hitsPerPage={5} distinct={true} />
          <AutoCompleteLocation
            onSuggestionSelected={this.onSuggestionSelected}
            onSuggestionCleared={this.onSuggestionCleared}
            handleChange={this.props.handleChange}
          />
        </InstantSearch>

        <InstantSearch indexName={index_name} searchClient={searchClient}>
          <VirtalSearchBox defaultRefinement={query} />
        </InstantSearch>
      </div>
    );
  }
}

function Hit(props) {
  return (
    <div>
      <Highlight attribute="city_search" hit={props.hit} />
    </div>
  );
}

Hit.propTypes = {
  hit: PropTypes.object.isRequired,
};

export default SearchInputBoxLocation;
