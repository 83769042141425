import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Highlight, connectAutoComplete, connectHighlight } from 'react-instantsearch-dom';
import AutoSuggest from 'react-autosuggest';

// Custom highlight
const HighlightNew = ({ highlight, attribute, hit }) => {
  const parsedHit = highlight({
    highlightProperty: '_highlightResult',
    attribute,
    hit,
  });

  return (
    <span className="">
      {parsedHit.map(
        (part, index) =>
          part.isHighlighted ? (
            <mark key={index}>{part.value}</mark>
          ) : (
            <span className="" key={index}>{part.value}</span>
          )
      )}
    </span>
  );
};

const CustomHighlight = connectHighlight(HighlightNew);
// Custom highlight

class AutoCompleteJob extends Component {
    static propTypes = {
      hits: PropTypes.arrayOf(PropTypes.object).isRequired,
      currentRefinement: PropTypes.string.isRequired,
      refine: PropTypes.func.isRequired,
      onSuggestionSelected: PropTypes.func.isRequired,
      onSuggestionCleared: PropTypes.func.isRequired,
    };
  
    state = {
      value: this.props.currentRefinement,
    };
  
    onChange = (_, { newValue }) => {
      if (!newValue) {
        this.props.onSuggestionCleared();
      }
      if(this.props.handleChange) {
      this.props.handleChange(newValue);
      }

      this.setState({
        value: newValue,
      });
    };
  
    onSuggestionsFetchRequested = ({ value }) => {
      this.props.refine(value);
    };
  
    onSuggestionsClearRequested = () => {
      this.props.refine();
    };
  
    getSuggestionValue(hit) {
      return hit.posting_title;
    }
  
    renderSuggestion(hit) {
      return <CustomHighlight attribute="posting_title" hit={hit} />;
      // return <Highlight attribute="industry" hit={hit} />;
    }
  
    render() {
      const { hits, onSuggestionSelected } = this.props;
      const { value } = this.state;
  
      const inputProps = {
        placeholder: 'Enter Your Job Title',
        onChange: this.onChange,
        value,      
      };

      // Remove duplicates
      function getUniqueListBy(hits, key) {
        return [...new Map(hits.map(item => [item[key], item])).values()]
      }
      const hitsNew = getUniqueListBy(hits, 'posting_title')
      // Remove duplicates
  
      return (
        <AutoSuggest
          suggestions={hitsNew}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
        />
      );
    }
  }
  
  export default connectAutoComplete(AutoCompleteJob);