import Cookies from "universal-cookie"

const cookies = new Cookies()

export const getCookie = name => {
  let data = cookies.get(name)
  if (data) return data
  else return []
}

export const isStoredInCookie = (name, key) => {
  let stored_data = cookies.get(name, [])
  if (stored_data && stored_data.includes(key)) return true
  return false
}

export const saveCookie = (name, key) => {
  // first get the cookie data
  let stored_data = cookies.get(name, [])
  let message = {
    response: "saved",
    count: 0,
  }

  if (!key) {
    message.response = "Invalid"
    return message
  }

  if (stored_data) {
    if (stored_data.includes(key)) {
      stored_data = stored_data.filter(x => x !== key)
      message.response = "removed"
    } else {
      stored_data.push(key)
    }
  } else {
    stored_data = [key]
  }

  let params = {
      path: "/",
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
  }

  cookies.set(name, stored_data, params)

  message.count = stored_data.length

  return message
}

// CUstom cookie code
/*export const getCookie = name => {
    if ('undefined' === name)
    name = name + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');

    console.log("ca => ", ca);

    //Itrate to each cookie values and found the requested cookie
    for(var i = 0; i <ca.length; i++) {

        var c = ca[i];

        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }

        if (c.indexOf(name) === 0) {
            var storedata = c.substring(name.length, c.length);
            console.log("storedata => ", storedata);
            if ('undefined' !== storedata && storedata)
                return JSON.parse(storedata);
        }

    }

    return '';
}

export const saveCookie = (name, key) => {
    // create a cookie that expires after 15 mins
    // 0.25 means 15 minutes
    var date = new Date();
    var hours = 0.25;
    date.setTime(date.getTime() + (hours * 60 * 60 * 1000));

    // Set the expiry time
    var expires = " expires=" + date.toGMTString() + ";";

    // Set the cookie variable to store the map points
    document.cookie = name + '=' + JSON.stringify(key) + "; " + expires + " path=/";

};

export const removeCookie = name => {
    // Removing cookie by set the past date
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
*/
